<template>
  <div>
    <van-nav-bar
      title="用工保障"
      left-text=""
      left-arrow
      @click-left="onClickLeft"
    />
    <img class="head" src="../assets/head.png" alt="" />
    <ul class="mian">
      <li><span></span>下简历 做背调 送保障</li>
      <li><span></span>员工离职 即刻赔付</li>
      <li><span></span>招聘损失 替您承担</li>
      <li><span></span>权威保障 企业安心 HR放心</li>
    </ul>
    <van-divider :style="{ borderColor: '#ccc', padding: '0.44rem' }" />
    <img class="huodong" src="../assets/huodong.png" alt="" />
    <ul class="content">
      <li>1.赔付时间：仅限员工试用期（90天）内；</li>
      <li>2.赔付金额：全额补偿背调成本（以平台支付记录为准）；</li>
      <li>3.活动保单获取和理赔请联系萝卜猎手官方客服；</li>
      <li>4.活动最终解释权归上海猎后征信服务有限公司</li>
    </ul>
    <div class="subsidy">补贴有限，仅限前1000家企业！</div>
  </div>
</template>

<script>
export default {
  methods: {
    onClickLeft() {
      this.$toast("返回");
    },
  },
};
</script>

<style scoped>
.head {
  box-sizing: border-box;
  width: 100%;
  padding: 0 0.2rem;
}
.mian li {
  line-height: 0.3rem;
  line-height: 0.6rem;
  font-size: 0.3rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
}
.mian li span {
  display: inline-block;
  width: 0.14rem;
  height: 0.14rem;
  border-radius: 50%;
  transform: translateY(-0.05rem);
  margin-left: 0.44rem;
  margin-right: 0.2rem;
  background: #f55814;
}
.huodong {
  width: 2.01rem;
  height: 0.77rem;
  margin-left: 0.44rem;
}
.content {
  margin-left: 0.44rem;
  margin: 0.2rem;
  padding: 0.3rem 0 0 0.25rem;
  display: inline-block;
  width: 6.58rem;
  height: 2.8rem;
  background: #fffaee;
  border-radius: 0.21rem;
}
.content li {
  font-size: 0.24rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #222222;
  line-height: 0.46rem;
}
.van-divider {
  margin-bottom: 0;
}
.subsidy {
  margin-left: 0.69rem;
  font-size: 0.32rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ff3e32;
}
</style>